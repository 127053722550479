const difference = (currentCount: number, maxCount: number) => Math.abs(currentCount - maxCount)

export const charCount = (currentCount: number, maxCount: number) => {
  const remainingCount: number = maxCount - currentCount
  const count: number = difference(currentCount, maxCount)
  const charText = count === 1 ? 'character' : 'characters'

  const countString =
    remainingCount < 0 ? `${count} ${charText} too many` : `${count} ${charText} remaining`

  return `You have ${countString}`
}
