import type { FunctionComponent } from 'react'
import React from 'react'
import { Checkbox, Dropdown, FormField, InputV2, TypographyV2 as Typography } from '@which/seatbelt'

import classNames from 'classnames'

import { cleanText } from '../../utils/clean-text'
import styles from './FormInput.module.scss'

export const FormInput: FunctionComponent<Props> = ({
  enQuestionId,
  error,
  hiddenQuestion,
  inputValue,
  label,
  mandatory,
  handleChange,
  handleKeyDown,
  handleKeyUp,
  placeholder,
  dropdownOptions = [],
  type,
}) => {
  if (type === 'checkbox') {
    return (
      <FormField
        className={classNames(styles.formCheckbox, {
          [styles.formInputHidden]: hiddenQuestion,
        })}
        label=""
        required={mandatory}
        labelFor={enQuestionId}
      >
        <Checkbox
          checked={inputValue === '' ? false : true}
          data-testid={`opt-in-${enQuestionId}`}
          id={enQuestionId}
          label={label}
          name={enQuestionId}
          onChangeCallback={handleChange}
          type="checkbox"
          value="1"
        />
        {error.hasError && (
          <Typography textStyle="sb-text-body-small-regular" className={styles.formCheckboxError}>
            {error.errorMessage}
          </Typography>
        )}
      </FormField>
    )
  }

  if (type === 'textarea') {
    return (
      <FormField
        className={classNames(styles.formFieldTextarea, {
          [styles.formInputHidden]: hiddenQuestion,
        })}
        errorMsg={error.hasError === true ? error.errorMessage : ''}
        label={label}
        labelFor={enQuestionId}
        required={mandatory}
        showRequiredText={false}
      >
        <textarea
          className={classNames(styles.formTextarea, {
            [styles.formTextareaError]: error.hasError,
          })}
          data-testid={`textarea-${enQuestionId}`}
          id={enQuestionId}
          name={enQuestionId}
          rows={8}
          required={mandatory}
          onBlur={(e) => (e.target.value = cleanText(e.target.value).trim())}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          onKeyUp={handleKeyUp}
          value={inputValue}
        />
      </FormField>
    )
  }

  if (type === 'dropdown') {
    if (!dropdownOptions.length) {
      return null
    }

    const handleSelectChange = (value: string) => {
      handleChange({ type: 'dropdown', id: enQuestionId, value })
    }

    return (
      <FormField
        className={classNames({
          [styles.formInputHidden]: hiddenQuestion,
        })}
        errorMsg={error.hasError === true ? error.errorMessage : ''}
        label={label}
        labelFor={enQuestionId}
        required={mandatory}
        showRequiredText={false}
      >
        <Dropdown
          className={styles.formDropdown}
          id={enQuestionId}
          name={enQuestionId}
          defaultValue={inputValue}
          options={dropdownOptions}
          placeholderOption={{
            label: 'Please select',
            value: 'prompt',
          }}
          variant="regular"
          callback={handleSelectChange}
        />
      </FormField>
    )
  }

  return (
    <FormField
      className={classNames({ [styles.formInputHidden]: hiddenQuestion })}
      errorMsg={error.hasError === true ? error.errorMessage : ''}
      label={label}
      labelFor={enQuestionId}
      required={mandatory}
      showRequiredText={false}
    >
      <InputV2
        data-testid={`textbox-${enQuestionId}`}
        id={enQuestionId}
        hasErrors={error.hasError}
        placeholder={placeholder}
        name={enQuestionId}
        required={mandatory}
        type="text"
        value={inputValue}
        onChange={handleChange}
      />
    </FormField>
  )
}

type Props = {
  enQuestionId: string
  error: {
    hasError: boolean
    errorMessage: string
  }
  handleChange: (e: any) => void
  handleKeyDown?: (e: any) => void
  handleKeyUp?: (e: any) => void
  hiddenQuestion?: boolean
  label: string
  mandatory: boolean
  placeholder: string
  inputValue: string
  dropdownOptions?: { label: string; value: string }[]
  type: string
}
