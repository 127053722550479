import type { ComponentPropsWithoutRef, FunctionComponent, ReactElement } from 'react'
import React, { cloneElement, isValidElement, useRef } from 'react'
import { Grid, GridItem, TypographyV2 as Typography } from '@which/seatbelt'

import classnames from 'classnames'

import type { Link as LinkType } from '../../../../generated/frontend'
import { Link } from '../../../../shared/components/Link'
import { ShowMore } from '../../../../shared/components/ShowMore'
import type { ShowMoreContentRenderer } from '../../../../shared/components/ShowMore/ShowMore'
import styles from './MostReadArticles.module.scss'

export const MostReadArticles: FunctionComponent<Props> = ({ title, links }) => {
  const ref = useRef<HTMLElement>(null)

  if (!links || links.length === 0) {
    return null
  }

  const mappedListItems: ReactElement[] = links.map((link, index) => (
    <MostReadListItem
      index={index}
      key={`${link.href}-${index}`}
      id={`most-read-link-${index + 1}`}
      data-which-id={`most-read-top-link-${index + 1}`}
      data-section={title}
      data-card-name={link.text}
      data-index={index + 1}
      {...link}
    />
  ))

  // Takes 6th link out of tab order and applies ID for focusing
  // Extra links added via CSS to avoid CLS
  const showMoreContentRenderer: ShowMoreContentRenderer = (linkElements) => {
    if (!Array.isArray(linkElements)) {
      return linkElements
    }

    const isExpanded = linkElements.length > 6

    if (isExpanded) {
      return <ol className={classnames(styles.list, styles.listExpanded)}>{linkElements}</ol>
    }

    // @TODO: Might want to find a better way to avoid casting linkElements[5] below
    return (
      <ol className={styles.list}>
        {linkElements.slice(0, 5)}
        {isValidElement(linkElements[5] as React.ReactNodeArray)
          ? cloneElement(linkElements[5], {
              isDecorative: true,
            })
          : linkElements[5]}
        {mappedListItems.slice(6).map((element) =>
          cloneElement(element, {
            className: styles.hiddenLink,
          })
        )}
      </ol>
    )
  }

  // Focus 6th link when show more button is clicked
  const focusCallback = () => {
    // TODO CTX-1472: change to using a ref when ref forwarding is added to Link components
    ref.current?.querySelector<HTMLElement>('#homepage-most-read-link-5')?.focus()
  }

  const headingId = 'homepage-most-read-links-heading'

  const moreButtonProps = {
    'data-which-id': 'News Link',
    'data-section': title,
    'data-card-name': 'Show more',
    className: styles.moreButton,
  }

  return (
    <section
      aria-labelledby={headingId}
      data-testid={'mostReadSection'}
      ref={ref}
      className={styles.top}
    >
      <Grid className={classnames('w-page-wrapper', styles.grid)}>
        <GridItem
          span={{ medium: 10, large: 10 }}
          columnStart={{ medium: 2, large: 2 }}
          className={styles.gridItem}
        >
          <Typography
            id={headingId}
            tag="h2"
            textStyle="sb-text-heading-large"
            className={styles.header}
          >
            Most read
          </Typography>
          <ShowMore
            contentRenderer={showMoreContentRenderer}
            initialCount={6}
            callback={focusCallback}
            moreButtonProps={moreButtonProps}
            className={styles.showMoreWrapper}
          >
            {mappedListItems}
          </ShowMore>
        </GridItem>
      </Grid>
    </section>
  )
}

///////// IMPLEMENTATION /////////

const MostReadListItem: FunctionComponent<MostReadListItemProps> = ({
  index,
  href,
  text,
  isDecorative = false,
  className,
  ...rest
}) => (
  <li
    className={classnames(styles.listItem, className)}
    {...(isDecorative && { 'aria-hidden': true })}
  >
    <Typography textStyle="sb-text-body-default-strong" tag="span" className={styles.number}>{`${
      index + 1
    }.`}</Typography>
    <Link
      className={styles.link}
      href={href}
      {...(isDecorative && {
        tabIndex: -1,
        className: classnames(styles.link, styles.decorativeLink),
      })}
      textStyle="sb-text-body-default-strong"
      {...rest}
    >
      {text}
    </Link>
  </li>
)

type MostReadListItemProps = {
  index: number
  isDecorative?: boolean
  className?: string
} & LinkType &
  ComponentPropsWithoutRef<'li'>

type Props = {
  title: string
  links: LinkType[]
}
