import type { FunctionComponent } from 'react'
import React from 'react'
import { GridItem, TypographyV2 as Typography } from '@which/seatbelt'
import {
  CampaignsIcon,
  SignaturesIcon,
  SupportersIcon,
} from '@which/seatbelt/src/components/Icons/Campaigns'

import styles from './CampaignsTakeAction.module.scss'

export const CampaignsTakeAction: FunctionComponent<Props> = ({
  header,
  standfirst,
  totalSignatures,
  totalSupporters,
  totalCampaigns,
}) => {
  const createDetails = (numbers: string, detail: string) => {
    const TakeActionIcon = iconMapper[detail]

    return (
      <div aria-label={`${numbers} ${detail}`} className={styles.campaignsTakeActionContent}>
        <TakeActionIcon className={styles.campaignsTakeActionIcon} />
        <div aria-hidden="true" className={styles.campaignsTakeActionContentWrapper}>
          <Typography
            tag="p"
            textStyle="sb-text-heading-large"
            className={styles.campaignsTakeActionNumber}
          >
            {numbers}
          </Typography>
          <Typography
            textStyle="sb-text-body-default-regular"
            className={styles.campaignsTakeActionDetail}
          >
            {detail}
          </Typography>
        </div>
      </div>
    )
  }

  return (
    <GridItem
      id={'section-campaigns-take-action'}
      span={{ medium: 8, large: 8 }}
      columnStart={{ medium: 3, large: 3 }}
    >
      <div data-testid="take-action" className={styles.campaignsTakeAction}>
        <Typography
          tag="h3"
          textStyle="sb-text-heading-large"
          className={styles.campaignsTakeActionHeader}
        >
          {header}
        </Typography>
        <Typography
          tag="p"
          textStyle="sb-text-body-default-regular"
          className={styles.campaignsTakeActionStandfirst}
        >
          {standfirst}
        </Typography>
        <div className={styles.campaignsTakeActionCampaigns}>
          {createDetails(totalCampaigns, 'Campaigns')}
          {createDetails(totalSignatures, 'Signatures')}
          {createDetails(totalSupporters, 'Supporters')}
        </div>
      </div>
    </GridItem>
  )
}

///////// IMPLEMENTATION /////////

const iconMapper = {
  Campaigns: CampaignsIcon,
  Signatures: SignaturesIcon,
  Supporters: SupportersIcon,
}

type Props = {
  header: string
  standfirst: string
  totalSignatures: string
  totalSupporters: string
  totalCampaigns: string
}
