import type { FunctionComponent } from 'react'
import { TypographyV2 as Typography } from '@which/seatbelt'

import classNames from 'classnames'

import { Link } from '../../../../../shared/components/Link'
import type { VerticalSitemapPageData } from '../../../vertical-types'
import styles from './Sitemap.module.scss'

export const Sitemap: FunctionComponent<Props> = ({ sitemapMenu }) => {
  if (!sitemapMenu?.items?.length) {
    return null
  }
  return (
    <div className={styles.sitemapWrapper} data-testid="about-which-sitemap">
      {renderMenuItems(sitemapMenu.items, true)}
    </div>
  )
}

///////// IMPLEMENTATION /////////

type Props = {
  sitemapMenu: VerticalSitemapPageData['sitemapMenu']
}

type MenuItems = VerticalSitemapPageData['sitemapMenu']['items']

const renderMenuItems = (menuTreeLevel: MenuItems, root: boolean) =>
  menuTreeLevel.map((currentItem, index) => (
    <div
      key={`${currentItem.label}-${index}`}
      className={
        root
          ? classNames(styles.sitemapRow, styles.rowBorder, styles.rootElement)
          : styles.sitemapRow
      }
    >
      {currentItem.url ? (
        <Link title={currentItem.label} textStyle="sb-text-heading-small" href={currentItem.url}>
          {currentItem.label}
        </Link>
      ) : (
        <Typography textStyle="sb-text-heading-medium">{currentItem.label}</Typography>
      )}
      {currentItem?.children?.length && (
        <div className={styles.rootMenuLevel}>
          {renderMenuItems(currentItem.children as MenuItems, false)}
        </div>
      )}
    </div>
  ))
