import type { FunctionComponent } from 'react'
import React, { useCallback, useState } from 'react'
import { Button, Checkbox, Grid, GridItem, TypographyV2 as Typography } from '@which/seatbelt'

import classNames from 'classnames'

import type { CampaignsHomepageContentItem } from '../../../../generated/frontend'
import { FormInput } from '../../../../shared/components/FormInput'
import { Link } from '../../../../shared/components/Link'
import { charCount } from '../../../../shared/utils/char-count'
import { pageSubmission } from '../../../../shared/utils/EngagingNetwork/pageSubmission'
import { isNotEmpty } from '../../../../shared/utils/is-not-empty'
import { validateCharCount } from '../../../../shared/utils/validate-char-count'
import { validatePetitionForm } from '../../../../shared/utils/validate-petition-form'
import styles from './CampaignsSupporter.module.scss'
import { supporterData } from './data'

export const CampaignsSupporter: FunctionComponent<Props> = ({ components }) => {
  const [showEmailTerms, setShowEmailTerms] = useState<boolean>(false)

  const headerComponent = components.find((component) => {
    return component.type === 'header'
  })

  const { formFields, checkboxes } = supporterData

  const fields: any = []
  const checkboxFields: any = []

  const state = {
    firstName: {
      value: '',
      error: false,
      mandatory: formFields[0].required,
      isDefaultQuestion: true,
    },
    lastName: {
      value: '',
      error: false,
      mandatory: formFields[1].required,
      isDefaultQuestion: true,
    },
    emailAddress: {
      value: '',
      error: false,
      mandatory: formFields[2].required,
      isDefaultQuestion: true,
    },
    postcode: {
      value: '',
      error: false,
      errorMessage: '',
      mandatory: formFields[3].required,
      isDefaultQuestion: true,
    },
    '869530': {
      value: '',
      error: false,
      mandatory: formFields[4].required,
      isDefaultQuestion: false,
    },
    optIn1: { value: '', error: false, mandatory: false, isDefaultQuestion: true },
  }

  const [enError, setEnError] = useState(false)
  const [formDetails, setFormDetails] = useState(state)
  const [validateFormDetails, setValidateFormDetails] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [characterCountError, setCharacterCountError] = useState(false)
  const [submittedSuccess, setSubmittedSuccess] = useState(false)
  const [keyMap, setKeyMap] = useState(new Map())

  const handleShowTerms = (e) => {
    e.preventDefault()
    setShowEmailTerms(!showEmailTerms)
  }

  const maxChars = 2500

  const renderCharCount = () => {
    return charCount(inputValue.length, maxChars)
  }

  const handleTextareaChange = (e: any) => {
    //below clears out a carriage return if there is only 1 char and it's a carriage return
    const array = e.target.value.split(/\r?\n/)
    if (e.target.value.length === 1 && array.length > 1) {
      setInputValue(e.target.value.replace(/[\n\r]/g, ''))
    } else {
      setInputValue(e.target.value)
    }
    setCharacterCountError(!validateCharCount(e.target.value.length, 2500))
    const validationError =
      !validateCharCount(e.target.value.length, 2500) || !isNotEmpty(e.target.value)
    setValidateFormDetails(validationError)
    handleFormChange(e)
  }

  const handleFormChange = (e: any) => {
    if (e.target) {
      const { name, value } = e.target
      setFormDetails({ ...formDetails, [name]: { ...formDetails[name], value: value } })
    }

    if (e.id) {
      const { id } = e

      const updatedFormOptions = {
        ...formDetails,
        [id]: { ...formDetails[id], value: !formDetails[id].value },
      }

      setFormDetails(updatedFormOptions)
    }

    setValidateFormDetails(!validateFormDetails)
  }

  const handleKeyDown = (event: React.KeyboardEvent) => {
    const keyPressed: string = event.key
    if (keyPressed === 'Alt') {
      keyMap.set(keyPressed, event.type === 'keydown')
    }
    if (keyPressed === 'Enter') {
      keyMap.set(keyPressed, event.type === 'keydown')
    }

    if (keyMap.get('Alt') && keyMap.get('Enter')) {
      setKeyMap(new Map())
      handleSubmit()
    }
  }

  const handleKeyUp = (event: React.KeyboardEvent) => {
    const keyPressed: string = event.key
    if (keyPressed === 'Alt') {
      setKeyMap(keyMap.set(keyPressed, event.type === 'keydown'))
    }
    if (keyPressed === 'Enter') {
      setKeyMap(keyMap.set(keyPressed, event.type === 'keydown'))
    }
  }

  const validateForm = useCallback(async () => {
    const { hasError, formDetailValidation, enData } = validatePetitionForm(formDetails)
    let enSubmissionError = false
    if (hasError) {
      setFormDetails({ ...formDetailValidation })
    } else {
      const ENResponse = await pageSubmission(enData, 129707)

      if (
        !ENResponse ||
        (ENResponse.status !== 'SUCCESS' && ENResponse.status !== 200 && !ENResponse.ok)
      ) {
        enSubmissionError = true
        setEnError(true)
      }
    }

    return { error: hasError, enSubmissionError }
  }, [formDetails])

  const handleSubmit = async () => {
    const { error, enSubmissionError } = await validateForm()

    if (error || characterCountError || enSubmissionError) {
      setSubmittedSuccess(false)
    } else {
      setSubmittedSuccess(true)
      const element = document.getElementById('section-campaigns-supporter')
      element?.scrollIntoView({ behavior: 'smooth' })
    }
  }

  formFields.forEach((supportField, index) => {
    const { label, placeholder, id, type, required } = supportField
    const isTextarea = type === 'textarea'

    const defaultInputProps: inputProps = {
      enQuestionId: id,
      hiddenQuestion: false,
      label: label,
      placeholder: placeholder,
      type: type,
      mandatory: required,
      error: {
        hasError: formDetails[id].error,
        errorMessage: formDetails[id].errorMessage || 'This field is required',
      },
      handleChange: isTextarea ? handleTextareaChange : handleFormChange,
      inputValue: formDetails[id].value,
      showQuestion: true,
    }

    const textareaProps: textareaInputProps = {
      handleKeyDown: handleKeyDown,
      handleKeyUp: handleKeyUp,
    }

    fields.push(
      <GridItem
        key={id}
        span={{ medium: !isTextarea ? 6 : 12, large: !isTextarea ? 3 : 6 }}
        columnStart={{ medium: index % 2 === 0 ? 1 : 7, large: index % 2 === 0 ? 4 : 7 }}
      >
        <FormInput {...defaultInputProps} {...(isTextarea && textareaProps)} />
        {isTextarea && (
          <Typography
            className={classNames(styles.campaignsSupporterTextareaCharCount, {
              [styles.campaignsSupporterTextareaCharCountError]: characterCountError,
            })}
            dataTestId="tool-count-text"
            textStyle="sb-text-body-default-regular"
          >
            {renderCharCount()}
          </Typography>
        )}
      </GridItem>
    )
  })

  checkboxes.forEach((supportField) => {
    const { label, id } = supportField
    checkboxFields.push(
      <GridItem span={{ medium: 12, large: 6 }} columnStart={{ medium: 1, large: 4 }} key={id}>
        <Checkbox
          id={id}
          name={id}
          data-testid={id}
          value=""
          type="checkbox"
          label={label}
          onChangeCallback={handleFormChange}
          className={styles.campaignsSupporterCheckbox}
        />
      </GridItem>
    )
  })

  return (
    <>
      {!submittedSuccess && (
        <GridItem
          id={'section-campaigns-supporter'}
          span={{ medium: 12, large: 12 }}
          columnStart={{ medium: 1, large: 1 }}
          className={styles.campaignsSupporter}
        >
          <Typography
            tag="h2"
            textStyle="sb-text-heading-large"
            className={styles.campaignsSupporterHeader}
          >
            {headerComponent?.data.header}
          </Typography>

          <Typography
            textStyle="sb-text-body-default-regular"
            className={styles.campaignsSupporterStandfirst}
          >
            {headerComponent?.data.standfirst}
          </Typography>

          <form method="post">
            <Grid className={styles.campaignsSupporterFields}>
              {fields}
              <GridItem span={{ medium: 12, large: 6 }} columnStart={{ medium: 1, large: 4 }}>
                {checkboxFields}
                <div>
                  <Link
                    className={styles.campaignsSupporterToggle}
                    data-testid="show-more-link"
                    href="#"
                    onClick={handleShowTerms}
                  >
                    {showEmailTerms ? 'Show less' : 'Show more'}
                  </Link>
                  {showEmailTerms && (
                    <Typography
                      className={classNames(
                        'sb-padding-top-2 sb-padding-bottom-2',
                        styles.campaignsSupporterOptin
                      )}
                      dataTestId="privacy-terms"
                      textStyle="sb-text-body-x-small-regular"
                    >
                      The{' '}
                      <Link
                        href="https://www.which.co.uk/help/general-terms-aYhBS9Y7TTtE"
                        textStyle="sb-text-interface-body-x-small-regular"
                      >
                        Which? group
                      </Link>{' '}
                      is the Consumers' Association (a charity) that owns Which? Ltd. It promotes
                      and protects consumer interests through information, policy work, campaigns
                      and legal actions. Which? Ltd provides most of the group's commercial
                      services, including legal, wills and switching services. Most of the content
                      on{' '}
                      <Link href="/" textStyle="sb-text-interface-body-x-small-regular">
                        which.co.uk
                      </Link>{' '}
                      is provided by Which? Ltd, with some content and services provided by
                      Consumers' Association including consumer rights pages, and information on its
                      work for consumers.{' '}
                      <Link
                        href="/about-which/who-we-are"
                        textStyle="sb-text-interface-body-x-small-regular"
                      >
                        Read more about the Which? group
                      </Link>
                      .
                    </Typography>
                  )}
                </div>

                <div className={styles.campaignsSupporterButtonWrapper}>
                  <Button
                    data-testid="submit-button"
                    className={styles.campaignsSupporterSubmitButton}
                    onClick={handleSubmit}
                  >
                    Support us
                  </Button>
                  {enError && (
                    <Typography
                      className={styles.campaignsSupporterErrorText}
                      data-testid="en-error-message"
                      textStyle={'sb-text-body-small-regular'}
                    >
                      Something went wrong, please try again
                    </Typography>
                  )}

                  <Typography
                    textStyle={'sb-text-body-x-small-regular'}
                    className={styles.campaignsSupporterSubText}
                  >
                    We will process your details in accordance with our{' '}
                    <Link
                      appearance="primary"
                      href="https://www.which.co.uk/help/which-privacy-notice-aGL107i27ybI"
                      textStyle="sb-text-interface-body-x-small-regular"
                    >
                      Privacy Notice
                    </Link>{' '}
                  </Typography>
                </div>
              </GridItem>
            </Grid>
          </form>

          <div className={styles.campaignsSupporterBackground} />
        </GridItem>
      )}
      {submittedSuccess && (
        <GridItem
          id={'section-campaigns-supporter'}
          span={{ medium: 12, large: 12 }}
          columnStart={{ medium: 1, large: 1 }}
          className={styles.campaignsSupporter}
        >
          <Typography
            tag="h2"
            textStyle="sb-text-heading-large"
            className={styles.campaignsSupporterHeader}
          >
            Thanks for your support
          </Typography>
          <Typography
            textStyle="sb-text-body-default-regular"
            className={styles.campaignsSupporterStandfirst}
          >
            {`${formDetails.firstName.value}, you're in!`}
            <br />
            <br />
            Thank you for supporting our work to make people's lives fairer, simpler and safer.
            <br />
            <br />
            We've sent you an email (if you don't receive it, please check your spam folder), and in
            the meantime, check out our current campaigns.
          </Typography>
        </GridItem>
      )}
    </>
  )
}

///////// IMPLEMENTATION /////////

type Props = {
  components: CampaignsHomepageContentItem[]
}

type inputProps = {
  enQuestionId: string
  hiddenQuestion: boolean
  label: string
  placeholder: string
  type: string
  mandatory: boolean
  error: {
    hasError: boolean
    errorMessage: string
  }
  handleChange: (e: string) => void
  inputValue: string
  showQuestion?: boolean
}

type textareaInputProps = {
  handleKeyDown: (e: React.KeyboardEvent) => void
  handleKeyUp: (e: React.KeyboardEvent) => void
}
