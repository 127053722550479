import type { FunctionComponent } from 'react'
import { GridItem, TypographyV2 as Typography } from '@which/seatbelt'

import type { VerticalAboutWhichPageQuery } from '../../../../../generated/frontend'
import { Link } from '../../../../../shared/components/Link'
import styles from './AboutWhichNavigation.module.scss'

export const VerticalAboutWhichNavigation: FunctionComponent<Props> = ({ aboutWhich, type }) => {
  if (!aboutWhich || !type) {
    return null
  }

  switch (type) {
    case 'articles':
      return renderArticlesComponent(aboutWhich)
    case 'widgets':
      return renderWidgetsComponent(aboutWhich)
  }
}

///////// IMPLEMENTATION /////////

type Props = {
  aboutWhich: VerticalAboutWhichPageQuery['verticalPage']['aboutWhich']
  type: 'articles' | 'widgets'
}

const renderArticlesComponent = (
  aboutWhich: VerticalAboutWhichPageQuery['verticalPage']['aboutWhich']
) => (
  <div data-testid="about-which-articles-navigation" className={styles.awNavWrapper}>
    {aboutWhich.subVerticalArticles.map((element, index) => (
      <GridItem
        key={`article${index}`}
        span={{ medium: 8, large: 6 }}
        columnStart={{
          medium: 1,
          large: (index + 1) % 2 === 0 ? 7 : 1,
        }}
        className={styles.navGridItem}
      >
        <Link className={styles.awArticleLink} textStyle="sb-text-heading-small" href={element.url}>
          {element.title}
        </Link>
        <Typography textStyle="sb-text-body-default-regular">{element.description}</Typography>
      </GridItem>
    ))}
  </div>
)

const renderWidgetsComponent = (
  aboutWhich: VerticalAboutWhichPageQuery['verticalPage']['aboutWhich']
) => (
  <div data-testid="about-which-widgets-navigation" className={styles.awPromoWrapper}>
    {aboutWhich.promoUrls.map((element, index) => (
      <GridItem
        key={'widget' + index}
        span={{ medium: 5, large: 5 }}
        columnStart={{
          medium: (index + 1) % 2 === 0 ? 7 : 2,
          large: (index + 1) % 2 === 0 ? 7 : 2,
        }}
        className={styles.navGridItem}
      >
        <div>
          <Link
            className={styles.awArticleLink}
            appearance="secondary"
            includeIcon={true}
            href={element.url}
          >
            {element.title}
          </Link>
          <Typography textStyle="sb-text-body-default-regular" className={styles.widgetsStandfirst}>
            {element.description}
          </Typography>
        </div>
      </GridItem>
    ))}
  </div>
)
