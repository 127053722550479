import type { FunctionComponent } from 'react'

import type { CampaignsHomepageContentItem } from '../../../../generated/frontend'
import { CampaignsHeader } from '../CampaignsHeader'
import { CampaignsHistory } from '../CampaignsHistory'
import { CampaignsSupporter } from '../CampaignsSupporter'
import { CampaignsTakeAction } from '../CampaignsTakeAction'
import { CampaignsWins } from '../CampaignsWins'
import { CurrentCampaigns } from '../CurrentCampaigns'
import { SectionsJumpLinks } from '../SectionJumpLinks/SectionsJumpLinks'

export const CampaignSections: FunctionComponent<Props> = ({ contentItems }) => {
  if (!contentItems.length) {
    return null
  }

  return (
    <>
      {contentItems.map(({ type, data }, index) => {
        const Component = componentMapper[type]
        if (Component) {
          return <Component {...data} key={`${type}-${index}`} />
        }

        return null
      })}
    </>
  )
}

///////// IMPLEMENTATION /////////

type Props = {
  contentItems: CampaignsHomepageContentItem[]
}

const componentMapper: Record<string, FunctionComponent<any>> = {
  sectionJumpLinks: SectionsJumpLinks,
  takeAction: CampaignsTakeAction,
  header: CampaignsHeader,
  wins: CampaignsWins,
  history: CampaignsHistory,
  current: CurrentCampaigns,
  supporter: CampaignsSupporter,
}
