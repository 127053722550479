import { trimPostcode } from './trim-postcode'
import { validateEmail } from './validateEmail'
import { validatePostcode } from './validatePostcode'

export const validatePetitionForm = (formDetails: any) => {
  let formHasErrors = false
  for (const key in formDetails) {
    if (key === 'postcode' && formDetails[key].value && !validatePostcode(formDetails[key].value)) {
      formDetails[key].error = true
      formDetails[key].errorMessage = 'Please enter a valid postcode'
      formHasErrors = true
    } else if (
      key === 'emailAddress' &&
      formDetails[key].value &&
      !validateEmail(formDetails[key].value)
    ) {
      formDetails[key].error = true
      formDetails[key].errorMessage = 'Please enter a valid email address'
      formHasErrors = true
    } else if (formDetails[key].mandatory && !formDetails[key].value) {
      formDetails[key].error = true
      formHasErrors = true
      formDetails[key].errorMessage = ''
    } else {
      formDetails[key].error = false
      formDetails[key].errorMessage = ''
    }
  }

  return {
    hasError: formHasErrors,
    formDetailValidation: formDetails,
    enData: buildDataForEN(formHasErrors, formDetails),
  }
}

const buildDataForEN = (formHasErrors: boolean, formDetails: any) => {
  const questions: any = {}

  if (formHasErrors) {
    return {}
  }

  for (const key in formDetails) {
    if (formDetails[key] && !formDetails[key].isDefaultQuestion) {
      questions[`question.${key}`] = formDetails[key].value
    }
  }

  if (formDetails['optIn1'].value) {
    questions['question.1598463'] = 'Y' // Single consent opt in
  }

  const supporter = {
    Email: formDetails['emailAddress'].value,
    'First name': formDetails['firstName'].value,
    'Last name': formDetails['lastName'].value,
    Postcode: trimPostcode(formDetails['postcode'].value),
    questions,
  }

  const removeEmptyProperties = Object.entries(supporter).reduce(
    (a, [k, v]) => (v ? ((a[k] = v), a) : a),
    {}
  )

  return {
    supporter: {
      ...removeEmptyProperties,
    },
  }
}
