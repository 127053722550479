import type { FunctionComponent } from 'react'
import React from 'react'
import { Grid, GridItem } from '@which/seatbelt'

import classnames from 'classnames'

import { VerticalHeader } from '../VerticalHeader'

export const FullWidthVerticalHeader: FunctionComponent<Props> = ({
  backgroundColour,
  ...props
}) => {
  return (
    <div
      className={classnames(backgroundColour && `sb-bg-color-${backgroundColour}`)}
      data-testid="full-width-vertical-header"
    >
      <Grid>
        <GridItem span={{ medium: 8, large: 8 }} columnStart={{ medium: 3, large: 3 }}>
          <VerticalHeader {...props} />
        </GridItem>
      </Grid>
    </div>
  )
}

///////// IMPLEMENTATION /////////

type Props = Parameters<typeof VerticalHeader>[0] & {
  backgroundColour?: string
}
