import { EN_API_GATEWAY, getENDomain } from './env'

export const pageSubmission = async (pageData: any, pageId: number) => {
  if (!pageData || Object.keys(pageData).length === 0 || !pageId) {
    throw new Error('Incorrect data passed in')
  }

  // Fetch auth token
  const authUrl = `${EN_API_GATEWAY()}/authtoken`

  const postToEN = async () => {
    const response = await fetch(authUrl)
      .then((authResponse) => {
        return authResponse.json()
      })
      .then((tokenData) => {
        const token = typeof tokenData == 'object' ? tokenData : JSON.parse(tokenData)
        const enFetchData = fetch(`${getENDomain()}/ens/service/page/${pageId}/process`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'ens-auth-token': token['ens-auth-token'],
          },
          body: JSON.stringify(pageData), // Data is stringified and pass as the request body
        })
          .then((responseData) => {
            if (responseData.ok) {
              return responseData
            } else {
              return {}
            }
          })
          .catch((err) => {
            return err
          })
        return enFetchData
      })
      .catch((err) => {
        return err
      })
    const data = await response

    return data
  }

  const enSubmissionResult = await postToEN()

  return enSubmissionResult
}
