import React from 'react'
import { GridItem } from '@which/seatbelt'

import type {
  LatestNews as LatestNewsType,
  NewsCard,
  NewsCardsCollections,
  VerticalNewsPageQuery,
} from '../../../../generated/frontend'
import { Breadcrumb } from '../../../../shared/components/Breadcrumb/Breadcrumb'
import { FeaturedContent } from '../../../../shared/components/FeaturedContent/FeaturedContent'
import { JumplinksInline } from '../../../../shared/components/JumplinksInline/JumplinksInline'
import { CollectionsList } from '../../components/CollectionsList'
import { MostReadArticles } from '../../components/MostReadArticles'
import { VerticalHeader } from '../../components/VerticalHeader'
import styles from '../../VerticalPage.module.scss'

export const getNewsVerticalComponents = (data: VerticalNewsPageQuery['verticalPage']) => {
  const {
    header,
    latestNews,
    newsCardsCollections: { collections, mainFeaturedArticle, mostReadArticles },
  } = data

  return {
    VerticalHeader: () => (
      <GridItem span={{ medium: 10, large: 10 }} columnStart={{ medium: 2, large: 2 }}>
        <VerticalHeader {...header} size={1} className={styles.verticalHeaderNews} />
      </GridItem>
    ),
    JumpLinks: () => (
      <GridItem>
        <JumplinksInline
          links={[
            { label: 'Latest news', id: 'jumplink-latest-news' },
            ...collections?.map(({ taxonomy }) => ({
              label: taxonomy.name,
              id: taxonomy.slug,
            })),
          ]}
        />
      </GridItem>
    ),
    VerticalNewsFeaturedContent: () => (
      <FeaturedContent
        latestNewsTitle="Latest news"
        latestNews={latestNews as LatestNewsType}
        latestNewsLink={{
          url: '/news/latest',
          text: 'See all News',
        }}
        mainFeaturedArticle={mainFeaturedArticle as NewsCard}
        lazyLoad={false}
      />
    ),
    NewsCollections: () => (
      <CollectionsList collections={collections as NewsCardsCollections['collections']} />
    ),
    Breadcrumb: () => <Breadcrumb heading={{ text: header.title }} currentPage={null} links={[]} />,
    MostReadArticles: () => <MostReadArticles {...mostReadArticles} />,
  }
}
