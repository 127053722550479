import type { FunctionComponent } from 'react'
import React from 'react'
import { CardRow, MultiLinkCard } from '@which/seatbelt'

import type { VerticalReviewsPageQuery } from '../../../../generated/frontend'

export const ArticleCards: FunctionComponent<Props> = ({ articleCards }) => {
  const { heading = '', cards = [] } = articleCards

  if (!cards.length) {
    return null
  }

  return (
    <div data-testid="article-cards-wrapper">
      <CardRow title={heading}>
        {cards.map((card) => (
          <MultiLinkCard
            key={card.heading}
            title={card.heading}
            display="scroll-row"
            viewAllLink={card.viewAll}
            links={card.links}
            image={card.image?.src}
            imageAlt={card.image?.alt}
            imageSources={card.image?.sources}
          />
        ))}
      </CardRow>
    </div>
  )
}

///////// IMPLEMENTATION /////////

type Props = {
  articleCards: VerticalReviewsPageQuery['verticalPage']['articleCards']
}
