import type { DataLayerInfo, PageInfo } from '../../../../shared/types'

export const getNewsFullListingDataLayer = (pageInfo: PageInfo) => {
  const newsDataLayer: DataLayerInfo = {
    wcdPageUrl: pageInfo.wcdPageUrl,
    content_type: 'news',
    pageType: 'news-all',
  }

  return newsDataLayer
}
