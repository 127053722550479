import type { FunctionComponent } from 'react'
import React from 'react'
import {
  ButtonLink,
  Grid,
  GridItem,
  Picture,
  Share,
  SignupButton,
  TypographyV2 as Typography,
} from '@which/seatbelt'
import { ChevronRightIcon } from '@which/seatbelt/src/components/Icons/Navigational'

import classnames from 'classnames'

import styles from './BannerSignUp.module.scss'

export const BannerSignUp: FunctionComponent<BannerSignUp> = ({
  image,
  title,
  text,
  linkText,
  linkUrl = '',
  backgroundColour,
  facebookUrl,
  twitterUrl,
  buttonType,
}) => {
  return (
    <>
      <div
        data-testid="banner-signup"
        className={classnames(backgroundColour && `sb-bg-color-${backgroundColour}`)}
      >
        <Grid className={'w-page-wrapper'}>
          <GridItem span={{ medium: 12, large: 12 }} columnStart={{ medium: 1, large: 1 }}>
            <div className={styles.bannerSignUpWrapper}>
              <GridItem
                span={{ medium: 5, large: 5 }}
                columnStart={{ medium: 2, large: 2 }}
                className={styles.bannerSignInfo}
              >
                <Typography
                  className={styles.bannerParagraph}
                  tag="h2"
                  textStyle="sb-text-heading-large"
                >
                  {title}
                </Typography>
                <Typography
                  className={styles.bannerParagraph}
                  textStyle="sb-text-body-default-regular"
                >
                  {text}
                </Typography>
                {buttonType === 'signUp' ? (
                  <SignupButton href={linkUrl} />
                ) : (
                  <div className={styles.bannerSignUpButtonWrapper}>
                    <ButtonLink containIcon={true} appearance={'secondary'} href={linkUrl}>
                      <ChevronRightIcon />
                      {linkText}
                    </ButtonLink>
                  </div>
                )}
              </GridItem>
              {image && (
                <GridItem
                  className={styles.bannerImageWrapper}
                  span={{ medium: 5, large: 4 }}
                  columnStart={{ medium: 8, large: 8 }}
                >
                  <Picture
                    className={styles.bannerImage}
                    {...image}
                    width="480"
                    height="480"
                    aspectRatioMain="one-to-one"
                  />
                </GridItem>
              )}
            </div>
          </GridItem>
        </Grid>
      </div>
      {facebookUrl && twitterUrl && (
        <Grid className={classnames('w-page-wrapper', styles.bannerSignUpShare)}>
          <GridItem
            className={styles.bannerSignUpShareBorder}
            span={{ medium: 8, large: 4 }}
            columnStart={{ medium: 3, large: 5 }}
          >
            <Share
              className={styles.bannerSignUpShareBorderItems}
              location={'bottom'}
              headline="Follow Which Money"
              orientation={'horizontal'}
              links={{
                facebook: facebookUrl,
                twitter: twitterUrl,
              }}
            />
          </GridItem>
        </Grid>
      )}
    </>
  )
}

///////// IMPLEMENTATION /////////

type BannerSignUp = {
  title?: string
  text?: string
  linkUrl?: string
  linkText?: string
  backgroundColour?: string
  image?: {
    src: string
    sources: ImageSources[]
  }
  facebookUrl?: string
  twitterUrl?: string
  buttonType?: string
}

type ImageSources = {
  srcset: string
  sizes: string
  type: string
}
