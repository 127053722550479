import type { DataLayerInfo, PageInfo } from '../../../../shared/types'

export const getNewsVerticalDataLayer = (pageInfo: PageInfo) => {
  const newsDataLayer: DataLayerInfo = {
    ...pageInfo,
    pageType: 'news-home',
  }

  delete newsDataLayer.vertical

  return newsDataLayer
}
