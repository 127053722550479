import { trimPostcode } from './trim-postcode'

export const validatePostcode = (postcode: string) => {
  // Trim and condense whitespace to a single space
  postcode = trimPostcode(postcode)

  const re =
    // This regex was recommended here: https://stackoverflow.com/a/164994
    // It fixes some of the problems the official UK postcode regex has, as explained above.

    // eslint-disable-next-line max-len
    /^(([A-Z]{1,2}[0-9][A-Z0-9]?|ASCN|STHL|TDCU|BBND|[BFS]IQQ|PCRN|TKCA) ?[0-9][A-Z]{2}|BFPO ?[0-9]{1,4}|(KY[0-9]|MSR|VG|AI)[ -]?[0-9]{4}|[A-Z]{2} ?[0-9]{2}|GE ?CX|GIR ?0A{2}|SAN ?TA1)$/i
  return re.test(postcode)
}
