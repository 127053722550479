import type { FunctionComponent } from 'react'
import React from 'react'

import type { Callout, VerticalPage as VerticalPageType } from '../../generated/frontend'
import { Error, PageError } from '../../shared/components/Error'
import { Loader } from '../../shared/components/Loader'
import { PageTemplate } from '../../shared/templates/PageTemplate'
import { useVerticalPageComponents } from './hooks/useVerticalPageComponents'
import { useVerticalPageMetaTags } from './hooks/useVerticalPageMetaTags'
import { useVerticalPageQuery } from './hooks/useVerticalPageQuery'

const VerticalPage: FunctionComponent = () => {
  const { loading, error, data } = useVerticalPageQuery()
  const { getPageMetaTags } = useVerticalPageMetaTags()
  const { getPageComponents } = useVerticalPageComponents()

  if (loading) {
    return <Loader />
  }

  if (error) {
    return <Error error={error} />
  }

  if (!data || !data.verticalPage) {
    return <PageError pageName="Vertical Page" />
  }

  const { verticalPage } = data
  const { callout, meta, header, transformTypeDecision, showResubBanner } =
    verticalPage as VerticalPageType

  return (
    <PageTemplate
      metaTags={getPageMetaTags({
        meta,
        callout: callout as Callout,
        // @TODO: Come back to this via WAB-16
        imageUrl: header?.image?.src,
      })}
      components={getPageComponents(verticalPage)}
      transformTypeDecision={transformTypeDecision}
      showResubBanner={showResubBanner}
    />
  )
}

export default VerticalPage
