import type { FunctionComponent } from 'react'
import React from 'react'
import { ArticleCard, CardRow } from '@which/seatbelt'

import type { LatestNews } from '../../../../generated/frontend'

export const VerticalLatestNews: FunctionComponent<Props> = ({ news }) => {
  if (!news?.articles?.length) {
    return null
  }

  return (
    <CardRow
      title={news.name}
      text="View all news"
      link={{ text: 'View all news', href: 'https://www.which.co.uk/news' }}
    >
      {news.articles.map(({ imageWithSources, ...article }) => (
        <ArticleCard
          key={`article_${article.title}`}
          {...article}
          image={imageWithSources?.src}
          imageSources={imageWithSources?.sources}
          authorName={article.authors?.length ? article.authors[0]?.name : undefined}
          authorImage={article.authors?.length ? article.authors[0]?.image?.src : undefined}
          display="scroll-row"
        />
      ))}
    </CardRow>
  )
}

///////// IMPLEMENTATION /////////

type Props = {
  news?: LatestNews
}
