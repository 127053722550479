import type { VerticalCampaignsPageQuery } from '../../../../generated/frontend'
import { Breadcrumb } from '../../../../shared/components/Breadcrumb'
import { CampaignSections } from '../../components/CampaignSections'

export const getCampaignsVerticalComponents = (
  data: VerticalCampaignsPageQuery['verticalPage']
) => {
  const {
    campaignsHomepageContent: { contentItems },
  } = data

  return {
    CampaignsHomepageContent: () => <CampaignSections contentItems={contentItems} />,
    Breadcrumb: () => (
      <Breadcrumb
        heading={{ text: 'Campaigns' }}
        currentPage={{ text: '', href: '' }}
        links={[]}
      />
    ),
  }
}
