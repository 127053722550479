import type { FunctionComponent } from 'react'
import React from 'react'
import { NavigationLinkBlock } from '@which/seatbelt'

import type { VerticalCrPageQuery } from '../../../../generated/frontend'
import styles from './SubVerticalLinks.module.scss'

export const SubVerticalLinks: FunctionComponent<Props> = ({ name, links }) => {
  const filteredLinks = links.filter((link) => link.children !== 'News')

  return (
    <div data-testid="subVerticalLinks" className={styles.wrapper}>
      <NavigationLinkBlock verticalName={name} links={filteredLinks} />
    </div>
  )
}

///////// IMPLEMENTATION /////////

type Props = VerticalCrPageQuery['verticalPage']['subVerticalLinks']
