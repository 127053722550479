import React from 'react'
import { GridItem } from '@which/seatbelt'

import { AdviceCards } from '../../../shared/components/AdviceCards/AdviceCards'
import { Breadcrumb } from '../../../shared/components/Breadcrumb/Breadcrumb'
import { TaxonomyConversionBanner } from '../../../shared/components/TaxonomyConversionBanner/TaxonomyConversionBanner'
import { SubVerticalLinks } from '../components/SubVerticalLinks'
import { VerticalHeader } from '../components/VerticalHeader'
import type { VerticalNewsPageData, VerticalVariantTypes } from '../vertical-types'
import styles from '../VerticalPage.module.scss'

export const getComponents = (
  data: Exclude<VerticalVariantTypes, VerticalNewsPageData>,
  rest = {}
) => {
  // @ts-expect-error - To be addressed WBA-83
  const { adviceCards, header, subVerticalLinks, taxonomyConversionBanner } = data
  return {
    TaxonomyConversionBanner: () => (
      <GridItem span={{ small: 'full-bleed', medium: 'full-bleed', large: 'full-bleed' }}>
        <TaxonomyConversionBanner {...taxonomyConversionBanner} />
      </GridItem>
    ),
    VerticalAdviceCards: () =>
      adviceCards?.cards?.length ? (
        <GridItem>
          <AdviceCards className={styles.adviceCardsWrapper} {...adviceCards} />
        </GridItem>
      ) : (
        <></>
      ),
    VerticalHeader: () => (
      <GridItem span={{ medium: 10, large: 10 }} columnStart={{ medium: 2, large: 2 }}>
        <VerticalHeader {...header} size={1} className={styles.verticalHeader} />
      </GridItem>
    ),
    VerticalSubVerticalLinks: () => (
      <GridItem span={{ medium: 10, large: 10 }} columnStart={{ medium: 2, large: 2 }}>
        <SubVerticalLinks {...subVerticalLinks} />
      </GridItem>
    ),
    Breadcrumb: () => (
      <Breadcrumb
        heading={{ text: header.title }}
        currentPage={{ text: header.title, href: '' }}
        links={[]}
      />
    ),
    ...rest,
  }
}
