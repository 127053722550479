export const supporterData = {
  formFields: [
    {
      label: 'First name',
      type: 'text',
      placeholder: '',
      id: 'firstName',
      required: true,
    },
    {
      label: 'Last name',
      type: 'text',
      placeholder: '',
      id: 'lastName',
      required: false,
    },
    {
      label: 'Email',
      type: 'email',
      placeholder: '',
      id: 'emailAddress',
      required: true,
    },
    {
      label: 'Postcode',
      type: 'text',
      placeholder: '',
      id: 'postcode',
      required: false,
    },
    {
      label: 'I think Which? should run the following campaign…',
      type: 'textarea',
      placeholder: '',
      id: '869530',
      required: false,
    },
  ],
  checkboxes: [
    {
      label:
        'Keep me updated about products and services from the Which? Group and the work it does to make life simpler, safer and fairer for UK consumers.',
      id: 'optIn1',
    },
  ],
}
