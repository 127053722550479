import React from 'react'
import type { NewsletterSource } from '@which/glide-ts-types'
import { GridItem } from '@which/seatbelt'

import classNames from 'classnames'

import type {
  ImageWithSources,
  LatestNews as LatestNewsType,
  PodcastCollection,
  VerticalReviewsPageQuery,
} from '../../../../generated/frontend'
import { NewsletterSignUpWithImage } from '../../../../shared/components/NewsletterSignUpWithImage/NewsletterSignUpWithImage'
import { PodcastInfo } from '../../../../shared/components/PodcastInfo/PodcastInfo'
import { LatestNews } from '../../../article/components/LatestNews'
import { BannerSignUp } from '../../../sub-vertical/components/BannerSignUp'
import { ArticleCards } from '../../components/ArticleCards'
import { FullWidthVerticalHeader } from '../../components/FullWidthVerticalHeader'
import styles from './VerticalReviews.module.scss'

export const getReviewsVerticalComponents = ({
  articleCards,
  latestNews,
  optionalData,
  header,
}: VerticalReviewsPageQuery['verticalPage']) => {
  return {
    verticalArticleCards: () => (
      <GridItem span={{ small: 'full-bleed', medium: 'full-bleed', large: 'full-bleed' }}>
        <ArticleCards articleCards={articleCards} />
      </GridItem>
    ),
    VerticalHeader: () => (
      <FullWidthVerticalHeader {...header} size={1} className={styles.verticalHeader} />
    ),
    VerticalLatestNews: () => (
      <GridItem span={{ medium: 12, large: 12 }}>
        <LatestNews news={latestNews as LatestNewsType} />
      </GridItem>
    ),
    NewsletterSignUp: () =>
      optionalData?.components?.newsletterSignUpWithImage ? (
        <GridItem span={{ medium: 12, large: 10 }} columnStart={{ large: 2 }}>
          <NewsletterSignUpWithImage
            image={optionalData.components.newsletterSignUpWithImage.image as ImageWithSources}
            source={optionalData.components.newsletterSignUpWithImage.source as NewsletterSource}
          />
        </GridItem>
      ) : (
        <></>
      ),
    Podcast: () =>
      optionalData?.components?.podcastComponentData ? (
        <div className={styles.moneyPodcastWrapper}>
          <GridItem
            className={classNames('w-page-wrapper', styles.podcastWrapper)}
            span={{ medium: 12, large: 10 }}
            columnStart={{ large: 2 }}
          >
            <PodcastInfo {...(optionalData.components.podcastComponentData as PodcastCollection)} />
          </GridItem>
        </div>
      ) : (
        <></>
      ),
    BannerSignUp: () =>
      optionalData?.components?.bannerSignUpComponentData ? (
        <BannerSignUp {...optionalData.components.bannerSignUpComponentData}></BannerSignUp>
      ) : (
        <></>
      ),
  }
}
