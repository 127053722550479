import type { FunctionComponent } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import React from 'react'
import {
  ArticleCard,
  GridItem,
  Label,
  Quote,
  Radio,
  TypographyV2 as Typography,
} from '@which/seatbelt'

import classnames from 'classnames'
import DOMPurify from 'dompurify'

import type { CampaignsHomepageContentItem } from '../../../../generated/frontend'
import { Link } from '../../../../shared/components/Link'
import styles from './CampaignsHistory.module.scss'

export const CampaignsHistory: FunctionComponent<Props> = ({ components }) => {
  const [header] = useState(
    components &&
      components.find((component) => {
        return component.type === 'historyHeader'
      })
  )
  const [history] = useState(
    components &&
      components.filter((component) => {
        return component.type === 'history'
      })
  )
  const [historyHeaders, setHistoryHeaders] = useState<string[] | []>([])
  const [historyItems, setHistoryItems] = useState<JSX.Element[] | []>([])
  const [radioChecked, setRadioChecked] = useState('')

  useEffect(() => {
    const historyHeaderArray: string[] = []
    const historyItemsArray: JSX.Element[] = []
    if (history) {
      history.map((historyItem) => {
        const historyComponents = historyItem.data.components
        const historyHeader = historyComponents.find((component) => {
          return component.type === 'header'
        })
        const historyCards = historyComponents.filter((component) => {
          return component.type === 'historyCard'
        })
        historyHeaderArray.push(historyHeader.data.header)
        historyItemsArray.push(
          <div
            aria-labelledby={`tab-${historyHeader.data.header}`}
            className={classnames(styles.campaignsHistoryItemHide, {
              [styles.campaignsHistoryItemShow]: radioChecked === historyHeader.data.header,
            })}
            data-testid="campaigns-history-item"
            id={`tabpanel-${historyHeader.data.header}`}
            key={historyHeader.data.header}
            role="tabpanel"
            tabIndex={0}
          >
            <Typography
              tag="h2"
              textStyle="sb-text-heading-medium"
              className={styles.campaignsHistoryItemHeader}
            >
              {historyHeader.data.header}
            </Typography>
            <Typography
              tag="p"
              textStyle="sb-text-body-default-regular"
              className={classnames(
                styles.campaignsHistoryBody,
                styles.campaignsHistoryItemStandfirst
              )}
            >
              {historyHeader.data.standfirst}{' '}
            </Typography>
            <div className={styles.campaignsHistoryItemContainer}>
              {historyCards.map((historyCard) => {
                return (
                  <ArticleCard
                    href=""
                    image={historyCard.data.img.src}
                    imageAlt={historyCard.data.img.alt}
                    strapline={historyCard.data.standfirst}
                    title={historyCard.data.header}
                    className={styles.campaignsHistoryCard}
                    clampStrapline={false}
                    key={historyCard.data.header}
                  />
                )
              })}
            </div>
          </div>
        )
      })
    }
    setHistoryHeaders(historyHeaderArray)
    setHistoryItems(historyItemsArray)
  }, [history, radioChecked])

  const mapStringToSeatbelt = (text: string) => {
    let copyOfText = text
    const split = (str: string, index: number) => {
      return [str.slice(0, index), str.slice(index)]
    }

    // Splits on p or a tags and pushes the index to an array
    const re = RegExp('<[(p|a)d](.*?)>', 'gi')
    const matches: number[] = []
    for (let result = re.exec(text); result !== null; result = re.exec(text)) {
      matches.push(result.index)
    }
    // reverse the array and start splitting the string based on the tags
    const arrayOfElementStrings: string[] = []
    matches.forEach((matchedIndex: number) => {
      const [first, second] = split(copyOfText, matchedIndex)
      arrayOfElementStrings.push(second)
      copyOfText = first
    })

    // map into seatbelt components
    const seatbeltElements = arrayOfElementStrings.map((elementString: string) => {
      if (elementString.substring(0, 2) === '<p') {
        const paragraphRegex = RegExp('<p(.*?)>', 'gi')
        const seatbeltReady = elementString.replace(paragraphRegex, '').replace('</p>', '')
        return (
          <Typography
            className={styles.campaignsHistoryBody}
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(seatbeltReady) }}
            key={elementString}
            textStyle="sb-text-body-default-regular"
          />
        )
      } else if (elementString.substring(0, 2) === '<a') {
        const div = document.createElement('div')
        div.innerHTML = elementString
        const link = {
          href: (div.firstChild as HTMLElement).getAttribute('href'),
          text: (div.firstChild as HTMLElement).innerHTML,
        }
        return (
          <Link key={elementString} href={link.href ?? ''}>
            {DOMPurify.sanitize(link.text)}
          </Link>
        )
      }
    })
    return seatbeltElements
  }

  if (historyHeaders && historyHeaders.length > 0 && radioChecked === '') {
    setRadioChecked(historyHeaders[0])
  }

  if (!history || !historyItems || !radioChecked) {
    return null
  }

  return (
    <>
      <GridItem
        id={'section-campaigns-history'}
        span={{ medium: 8, large: 8 }}
        columnStart={{ medium: 3, large: 3 }}
      >
        <div className={styles.campaignsHistory} data-testid="campaigns-history-intro">
          <Typography
            tag="h3"
            textStyle="sb-text-heading-large"
            className={styles.campaignsHistoryHeader}
          >
            {header?.data.header}
          </Typography>
          <div className={styles.campaignsHistoryIntro}>
            {mapStringToSeatbelt(header?.data.topText)}
            <Quote
              authorName={header?.data.quoteBy}
              className={styles.campaignsHistoryQuote}
              jobTitle={header?.data.quoteByTitle}
              quote={header?.data.quote}
            />
            {mapStringToSeatbelt(header?.data.bottomText)}
          </div>
          <div className={styles.campaignsHistoryLabelContainer} role="tablist">
            {historyHeaders.map((historyHeader: string, index: number) => {
              const isSelected = radioChecked ? radioChecked === historyHeader : index === 0

              return (
                <div
                  aria-controls={`tabpanel-${historyHeader}`}
                  aria-label={historyHeader}
                  aria-selected={isSelected}
                  className={styles.campaignsHistoryRadioContainer}
                  id={`tab-${historyHeader}`}
                  key={historyHeader}
                  role="tab"
                  {...(!isSelected && { tabindex: '-1' })}
                >
                  <Label
                    htmlFor={historyHeader}
                    labelText={historyHeader}
                    withAnimation={true}
                    className={classnames(styles.campaignsHistoryLabel, {
                      [styles.campaignsHistoryLabelChecked]: isSelected,
                    })}
                  />
                  <Radio
                    type="radio"
                    id={historyHeader}
                    key={historyHeader}
                    name="historyGroup"
                    value={historyHeader}
                    label=""
                    checked={isSelected}
                    aria-checked={isSelected}
                    className={styles.campaignsHistoryRadio}
                    onChange={() => {
                      setRadioChecked(historyHeader)
                    }}
                  />
                  <div
                    className={classnames(styles.campaignsHistoryArrowUp, {
                      [styles.campaignsHistoryItemHide]: !isSelected,
                    })}
                  />
                </div>
              )
            })}
            <hr className={styles.campaignsHistoryLine} />
          </div>
          <div className={styles.campaignsHistoryBackground} />
        </div>
      </GridItem>
      <GridItem
        className={styles.campaignsHistoryItemSection}
        columnStart={{ medium: 1, large: 1 }}
        span={{ medium: 12, large: 12 }}
      >
        {historyItems}
        <div className={styles.campaignsHistoryBackground} />
      </GridItem>
    </>
  )
}

///////// IMPLEMENTATION /////////

type Props = {
  components: CampaignsHomepageContentItem[]
}
