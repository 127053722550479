import type { FunctionComponent } from 'react'
import React, { useState } from 'react'
import { ButtonLink, Grid, GridItem, LinkButton, TypographyV2 as Typography } from '@which/seatbelt'
import { MinusIcon, PlusIcon } from '@which/seatbelt/src/components/Icons/Navigational'

import styles from './MyAppointment.module.scss'

export const MyAppointment: FunctionComponent<MyAppointmentProps> = ({
  title,
  refNumber,
  caseType,
  date,
  time,
  description,
  buttonText,
  buttonLink,
  isEntitled,
  notEntitledInfo,
}) => {
  const [isExpanded, setIsExpanded] = useState(false)
  return (
    <div className={styles.myAppointment}>
      <Grid>
        <GridItem span={{ large: 12, xlarge: 6 }} columnStart={{ medium: 1, xlarge: 1 }}>
          <Typography textStyle="sb-text-heading-small" tag="h4">
            {title}
          </Typography>
          <Typography
            textStyle="sb-text-body-small-regular"
            className={styles.myAppointmentRefNumber}
          >
            Case reference number: {refNumber}
          </Typography>
          <div className={styles.myAppointmentDetails}>
            <Typography
              textStyle="sb-text-body-default-regular"
              className={styles.myAppointmentDetail}
              tag="span"
            >
              {caseType}
            </Typography>
            <Typography
              textStyle="sb-text-body-default-regular"
              className={styles.myAppointmentDetail}
              tag="span"
            >
              {date}
            </Typography>
            <Typography
              textStyle="sb-text-body-default-regular"
              className={styles.myAppointmentDetail}
              tag="span"
            >
              {time}
            </Typography>
          </div>
        </GridItem>
        <GridItem span={{ medium: 3, large: 3 }} columnStart={{ medium: 10, large: 10 }}>
          <ButtonLink
            className={styles.myAppointmentButton}
            appearance="secondary"
            data-testid="my-appointment-btn-call"
            href={buttonLink}
            disabled={!isEntitled}
          >
            {buttonText}
          </ButtonLink>
          {!isEntitled && notEntitledInfo && (
            <div className={styles.myAppointmentNotEntitled}>{notEntitledInfo}</div>
          )}
        </GridItem>
        <GridItem
          span={{ large: 12, xlarge: 8 }}
          columnStart={{ medium: 1, large: 1 }}
          className={styles.myAppointmentButtonContainer}
        >
          <div>
            {description && (
              <LinkButton
                aria-atomic
                aria-pressed={isExpanded}
                data-testid="my-appointment-expand"
                onClick={() => {
                  setIsExpanded(!isExpanded)
                }}
                icon={isExpanded ? MinusIcon : PlusIcon}
              >
                <Typography tag="span" textStyle="sb-text-interface-body-small-regular">
                  {isExpanded ? 'Hide description' : 'Show description'}
                </Typography>
              </LinkButton>
            )}
            <ButtonLink
              data-testid="my-appointment-btn-call"
              className={styles.myAppointmentButtonLower}
              appearance="secondary"
              disabled={!isEntitled}
              href={buttonLink}
            >
              {buttonText}
            </ButtonLink>
            {!isEntitled && notEntitledInfo && (
              <div className={styles.myAppointmentNotEntitledLower}>{notEntitledInfo}</div>
            )}
          </div>
          {isExpanded && description && (
            <Typography
              textStyle="sb-text-body-default-regular"
              className={styles.myAppointmentDescription}
            >
              {description}
            </Typography>
          )}
        </GridItem>
      </Grid>
    </div>
  )
}

export type MyAppointmentProps = {
  title: string
  refNumber: string
  description?: string
  caseType: string
  date: string
  time: string
  buttonText: string
  buttonLink: string
  isEntitled: boolean
  notEntitledInfo: JSX.Element | null
}
