import type { Callout, VerticalCrPageQuery } from '../../../generated/frontend'
import { useBaseMetaTags } from '../../../shared/hooks/useBaseMetaTags'
import { usePageProps } from '../../../shared/usePageProps'
import { useVerticalDatalayer } from './useVerticalDataLayer'

export const useVerticalPageMetaTags = () => {
  const { getBaseMetaTags } = useBaseMetaTags()
  const { getDataLayerItems } = useVerticalDatalayer()
  const { context } = usePageProps()

  return {
    getPageMetaTags({ meta, imageUrl, callout }: VerticalMetaProps) {
      const image =
        imageUrl ||
        // TODO: come back to this - CDD-2156
        (callout && callout.items?.length !== 0 && callout.items[0]?.image?.src) ||
        undefined
      return [
        ...getBaseMetaTags({ meta, imageUrl: image, context }),
        ...getDataLayerItems(meta, callout),
      ]
    },
  }
}

///////// IMPLEMENTATION /////////

type VerticalMetaProps = {
  callout: Callout
  imageUrl?: string
  meta: VerticalCrPageQuery['verticalPage']['meta']
}
