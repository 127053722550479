import type { FunctionComponent } from 'react'
import React, { useEffect, useState } from 'react'
import { Redirect, useLocation } from 'react-router-dom'

import { Loader } from '../../../../shared/components/Loader'
import { environment } from '../../../../shared/utils/getEntitlements/getEntitlements'
import { findBlaizeSessionAvailable } from '../../../account/utils'
import type { MyAppointmentProps } from '../MyAppointment/MyAppointment'
import { MyAppointments } from '../MyAppointments'

const fetchCases = async (sessionId: string): Promise<CustomerAppointmentsResponse> => {
  const noCases = { pastCases: [], upcomingCases: [], clientId: '' }
  if (!sessionId) {
    return noCases
  }

  const fetchCasesApi = fetch(
    `https://${environment()}-appointment-booking-rebooking-service.api.product.which.co.uk/rebooking-service/customer-appointments`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: sessionId,
      },
    }
  )
    .then((responseData) => {
      if (responseData.ok) {
        return responseData.json()
      } else {
        return noCases
      }
    })
    .catch(() => {
      return noCases
    })
  const appointmentData = await fetchCasesApi
  return appointmentData
}

const mapCase = ({
  caseToMap,
  pastAppointment = false,
  clientId,
}: {
  caseToMap: CaseView
  pastAppointment?: boolean
  clientId?: string
}) => {
  const mapType = (type: string) => {
    const buildLink = (membership: string, link: string) => {
      return (
        <>
          <a className="sb-link-primary" href={link}>
            <span className="sb-link-animation-wrapper">Renew your {membership} membership</span>
          </a>{' '}
          to book a follow up
        </>
      )
    }

    switch (type) {
      case 'tech':
        return {
          title: 'Tech support',
          link: buildLink('Tech support', 'https://signup.which.co.uk/wlp-tech-support'),
        }
      case 'money':
        return {
          title: 'Money',
          link: buildLink('Money helpline', 'https://signup.which.co.uk/wlp-money'),
        }
      default:
        return {
          title: 'Legal',
          link: buildLink('Legal', 'https://legalservice.which.co.uk/'),
        }
    }
  }
  const createLink = () => {
    if (pastAppointment) {
      return `/tool/book-follow-up?appointmentId=${appointment.id}`
    }
    return `/tool/change-appointment?appointmentId=${appointment.id}&clientId=${clientId}`
  }
  const { caseNumber, title, category, description, appointment, isEntitled } = caseToMap

  const date = new Date(appointment.startTime)
  const serviceDetails = mapType(category)
  const time = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
  const endTime = new Date(appointment.endTime).toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  })
  return {
    title,
    refNumber: caseNumber,
    description,
    caseType: serviceDetails.title,
    date: `${date.toLocaleDateString([])}`,
    time: `${time}-${endTime}`,
    buttonText: pastAppointment ? 'Book follow up' : 'Cancel or reschedule',
    buttonLink: createLink(),
    isEntitled,
    notEntitledInfo: pastAppointment ? serviceDetails.link : null,
  }
}

export const AllAppointments: FunctionComponent = () => {
  const [cases, setCases] = useState<{
    clientId: string
    pastCases: CaseView[]
    upcomingCases: CaseView[]
  } | null>(null)
  const blaizeId = typeof window === 'undefined' ? '' : findBlaizeSessionAvailable('blaize_session')
  const location = useLocation()

  useEffect(() => {
    const fetchCasesApi = async () => {
      if (blaizeId) {
        const response = await fetchCases(blaizeId)
        setCases(response)
      }
    }
    fetchCasesApi()
  }, [blaizeId])

  if (!blaizeId && typeof window !== 'undefined') {
    return <Redirect to={`/login?return_url=${location.pathname.slice(1)}${location.search}`} />
  } else if (cases === null) {
    return <Loader />
  }

  const pastAppointments: MyAppointmentProps[] = cases.pastCases.map((singleCase) =>
    mapCase({ caseToMap: singleCase, pastAppointment: true })
  )
  const upcomingAppointments: MyAppointmentProps[] = cases.upcomingCases.map((singleCase) =>
    mapCase({ caseToMap: singleCase, clientId: cases.clientId })
  )
  return (
    <>
      <MyAppointments
        appointments={upcomingAppointments}
        id="upcoming-appointments"
        title="Upcoming appointments"
        standFirst="Our appointments are booked within a window, and you may receive your call at any point within that time frame, depending on availability."
        noAppointmentData="You have no upcoming appointments"
      />
      <MyAppointments
        appointments={pastAppointments}
        id="past-appointments"
        title="Past appointments"
        standFirst="Please do not book follow up appointments for new issues. Only book follow up appointments if you want to carry on discussing the same issue with an expert."
        noAppointmentData="You have no past appointments"
      />
    </>
  )
}

type AppointmentView = {
  id: string
  startTime: string
  endTime: string
}

type CaseView = {
  caseNumber: string
  title: string
  category: 'legal' | 'tech' | 'money'
  isEntitled: boolean
  appointment: AppointmentView
  description?: string
}

type CustomerAppointmentsResponse = {
  clientId: string
  upcomingCases: CaseView[]
  pastCases: CaseView[]
}
