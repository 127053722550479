import React from 'react'
import { GridItem } from '@which/seatbelt'

import type { VerticalPolicyAndInsightPageQuery } from '../../../../generated/frontend'
import { Breadcrumb } from '../../../../shared/components/Breadcrumb/Breadcrumb'
import { PIArticleCard } from '../../../../shared/components/PolicyAndInsight/PIArticleCard/PIArticleCard'
import { PIArticleSection } from '../../../../shared/components/PolicyAndInsight/PIArticleSection/PIArticleSection'
import { PICarouselSection } from '../../../../shared/components/PolicyAndInsight/PICarouselSection/PICarouselSection'
import { PICategories } from '../../../../shared/components/PolicyAndInsight/PICategories/PICategories'
import { PIHero } from '../../../../shared/components/PolicyAndInsight/PIHero/PIHero'
import { PILatestStatisticsSection } from '../../../../shared/components/PolicyAndInsight/PILatestStatisticsSection/PILatestStatisticsSection'
import { PIMoreFrom } from '../../../../shared/components/PolicyAndInsight/PIMoreFrom/PIMoreFrom'
import { PISection } from '../../../../shared/components/PolicyAndInsight/PISection/PISection'
import { PITrendingTopicsSection } from '../../../../shared/components/PolicyAndInsight/PITrendingTopicsSection/PITrendingTopicsSection'
import { checkSessionStorageExists } from '../../../../shared/utils/checkSessionStorageExists'
import { tryParseJSON } from '../../../../shared/utils/try-parse-json'

export const getPolicyAndInsightVerticalComponents = (
  data: VerticalPolicyAndInsightPageQuery['verticalPage']
) => {
  const {
    policyAndInsightCategories,
    policyAndInsightCardsCollections: { collections, hero },
  } = data

  if (checkSessionStorageExists('policyAndInsightPage')) {
    sessionStorage.removeItem('policyAndInsightPage')
  }

  if (checkSessionStorageExists('pageUrl')) {
    sessionStorage.removeItem('pageUrl')
  }

  const latestReleases = collections.find(
    (o) => o.taxonomy.name === 'Policy and Insight Latest Releases'
  )
  const trendingTopics = collections.find(
    (o) => o.taxonomy.name === 'Policy and Insight Trending Topics'
  )
  const latestData = collections.find((o) => o.taxonomy.name === 'Policy and Insight Latest Data')
  const moreFromData = collections.find((o) => o.taxonomy.name === 'More From')

  return {
    PIHero: ({ ...props }) => {
      const {
        buttons,
        sectionText,
        subtext,
        title,
        searchBarPlaceholder,
        searchBarButtonText,
        image,
      } = props

      return (
        <GridItem span={{ large: 10 }} columnStart={{ large: 2 }}>
          <PIHero
            buttonsRole={tryParseJSON(buttons)}
            standfirst={sectionText}
            subtext={subtext}
            title={title}
            image={image}
            searchBar={{ placeholderText: searchBarPlaceholder, buttonText: searchBarButtonText }}
          />
        </GridItem>
      )
    },
    PIArticleSection: ({ ...props }) => {
      const { sectionText, title } = props

      return (
        <PIArticleSection sectionHeader={title} sectionText={sectionText}>
          {hero &&
            hero.cards &&
            hero.cards.map(
              ({ ariaLabel, buttonText, linkUrl, image, standfirst, title: cardTitle }, i) => (
                <PIArticleCard
                  key={`${cardTitle}-${i}`}
                  imgObj={image}
                  title={cardTitle}
                  body={standfirst}
                  linkObj={{
                    href: linkUrl,
                    text: buttonText,
                    ariaLabel: ariaLabel,
                  }}
                  grandparentText={title}
                  cardIndex={i}
                />
              )
            )}
        </PIArticleSection>
      )
    },
    PICategoriesSection: ({ ...props }) => {
      const { sectionText, title } = props
      return (
        <PISection
          sectionHeader={title}
          sectionText={sectionText}
          cssClassName="categories"
          dataTestId="subject-areas"
        >
          <PICategories categories={policyAndInsightCategories.categories} />
        </PISection>
      )
    },
    PITopicsSection: ({ ...props }) => {
      const { sectionText, title } = props

      return (
        <PISection
          sectionHeader={title}
          sectionText={sectionText}
          cssClassName="topics"
          showViewAllArticles
          filter="trending_topics"
          dataTestId="trending-topics"
        >
          <PITrendingTopicsSection articleCards={trendingTopics?.cards} parentText={title} />
        </PISection>
      )
    },
    PILatestReleasesSection: ({ ...props }) => {
      const { sectionText, title } = props
      return (
        <PISection
          sectionHeader={title}
          sectionText={sectionText}
          cssClassName="latestReleases"
          showViewAllArticles
          filter="latest_releases"
          dataTestId="latest-releases"
        >
          <PICarouselSection articleCards={latestReleases?.cards} parentText={title} />
        </PISection>
      )
    },
    PILatestStatisticsSection: ({ ...props }) => {
      const { sectionText, title, buttonText, image } = props

      return (
        <PILatestStatisticsSection
          image={image}
          sectionHeader={title}
          sectionText={sectionText}
          buttonText={buttonText}
          latestStatisticsCards={latestData?.cards}
          filter="latest_statistics"
        />
      )
    },
    PIMoreFromSection: () => {
      if (moreFromData && moreFromData.cards && moreFromData.cards.length > 0) {
        return <PIMoreFrom title={moreFromData?.taxonomy.slug} cards={moreFromData.cards} />
      }
      return null
    },
    Breadcrumb: () => (
      <Breadcrumb
        heading={{ text: 'Policy and insight' }}
        currentPage={{ text: 'Policy and insight', href: '' }}
        links={[]}
      />
    ),
  }
}
