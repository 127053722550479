import type { FunctionComponent } from 'react'
import React from 'react'

import classnames from 'classnames'

import type { NewsCardsCollectionItem } from '../../../../generated/frontend'
import { CollectionSection } from '../CollectionSection'
import styles from './CollectionsList.module.scss'

export const CollectionsList: FunctionComponent<Props> = ({ collections }) => {
  if (!collections?.length) {
    return null
  }

  return (
    <div data-testid="collections-news-list">
      {collections.map((collectionItem, index: number) => (
        <div
          key={index}
          id={collectionItem.taxonomy.slug}
          data-testid={`collection-row-${index}`}
          className={classnames(styles.collection, index % 2 === 0 && styles.isEven)}
        >
          <CollectionSection {...collectionItem} />
        </div>
      ))}
    </div>
  )
}

///////// IMPLEMENTATION /////////

type Props = {
  collections: NewsCardsCollectionItem[]
}
