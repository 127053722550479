import type { VerticalCrPageQuery } from '../../../../generated/frontend'
import type { PageInfo } from '../../../../shared/types'

export const getCRVerticalDataLayer = (
  pageInfo: PageInfo,
  callout: VerticalCrPageQuery['verticalPage']['callout']
) => {
  const promoItemText = getPromoItemText(callout)
  const promoData: PageInfo = {
    event: 'promoShown',
    wcdPageUrl: pageInfo.wcdPageUrl,
    content_type: pageInfo.content_type,
    promoContent: promoItemText,
    vertical: pageInfo.vertical,
  }

  return promoData
}

///////// IMPLEMENTATION /////////

const getPromoItemText = (callout: VerticalCrPageQuery['verticalPage']['callout']) => {
  const items = callout?.items ?? []
  const promoItemText = items.reduce((promoText: string, item, index) => {
    const separator = index + 1 < items.length ? ' | ' : ''
    return `${promoText}${item.title}${separator}`
  }, '')

  return promoItemText
}
