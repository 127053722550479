import type { FunctionComponent } from 'react'
import React from 'react'
import { Hero } from '@which/seatbelt'

export const VerticalHeader: FunctionComponent<Props> = ({
  description,
  image,
  standfirst,
  title,
  size,
  className,
  links,
}) => {
  return (
    <div data-testid="vertical-header" className={className}>
      <Hero
        description={description}
        image={image}
        title={title}
        standfirst={standfirst}
        size={size}
        lazyLoad={false}
        links={links}
      />
    </div>
  )
}
///////// IMPLEMENTATION /////////

type Props = Parameters<typeof Hero>[0]
