import type { FunctionComponent } from 'react'
import React from 'react'
import { GridItem, TypographyV2 as Typography } from '@which/seatbelt'

import { JumplinksInline } from '../../../../shared/components/JumplinksInline'
import styles from './SectionsJumpLinks.module.scss'

export const SectionsJumpLinks: FunctionComponent<Props> = ({
  sectionText1,
  sectionId1,
  sectionText2,
  sectionId2,
  sectionText3,
  sectionId3,
  sectionText4,
  sectionId4,
}) => {
  const jumpLinks: { id: string; label: string; slug: string }[] = []

  if (sectionText1 && sectionId1) {
    jumpLinks.push({ id: sectionId1, label: sectionText1, slug: `section-${sectionId1}` })
  } else {
    return null
  }

  if (sectionText2 && sectionId2) {
    jumpLinks.push({ id: sectionId2, label: sectionText2, slug: `section-${sectionId2}` })
  }

  if (sectionText3 && sectionId3) {
    jumpLinks.push({ id: sectionId3, label: sectionText3, slug: `section-${sectionId3}` })
  }

  if (sectionText4 && sectionId4) {
    jumpLinks.push({ id: sectionId4, label: sectionText4, slug: `section-${sectionId4}` })
  }

  return (
    <>
      {jumpLinks && (
        <GridItem
          span={{ medium: 12, large: 12 }}
          columnStart={{ medium: 1, large: 1 }}
          className={styles.jumplinksHolder}
        >
          <Typography
            tag="h3"
            textStyle="sb-text-body-default-strong"
            className={styles.jumplinksHeader}
          >
            In this article
          </Typography>
          <JumplinksInline links={jumpLinks} />
        </GridItem>
      )}
    </>
  )
}

///////// IMPLEMENTATION /////////

type Props = {
  sectionText1?: string
  sectionId1?: string
  sectionText2?: string
  sectionId2?: string
  sectionText3?: string
  sectionId3?: string
  sectionText4?: string
  sectionId4?: string
}
