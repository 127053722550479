import type { FunctionComponent } from 'react'
import React, { useEffect, useState } from 'react'
import { GridItem, TypographyV2 as Typography } from '@which/seatbelt'
import { MinusIcon, PlusIcon } from '@which/seatbelt/src/components/Icons/Navigational'

import type { CampaignsHomepageContentItem } from '../../../../generated/frontend'
import { MoreButton, StandardCard } from '../../../../shared/components'
import styles from './CampaignsWins.module.scss'

export const CampaignsWins: FunctionComponent<Props> = ({ components }) => {
  const headerComponent = components.find((component) => {
    return component.type === 'header'
  })

  const campaignWinCount = 3

  const winsComponent = components.filter((component) => {
    return component.type === 'wins'
  })

  const slicedWinsComponent = winsComponent.slice(0, campaignWinCount)

  const buttonText = {
    more: 'Show more',
    less: 'Show less',
  }

  const [displayShowMoreButton, setDisplayShowMoreButton] = useState(false)
  const [showMoreButton, setShowMoreButton] = useState({
    expanded: false,
    buttonText: buttonText.more,
    buttonIcon: PlusIcon,
  })

  const winsComponentCards = showMoreButton.expanded ? winsComponent : slicedWinsComponent

  useEffect(() => {
    const cards = winsComponent

    if (cards.length >= campaignWinCount) {
      setDisplayShowMoreButton(true)
    } else {
      setDisplayShowMoreButton(false)
    }
  }, [winsComponent])

  const handleShowMoreClick = () => {
    if (showMoreButton.expanded) {
      setShowMoreButton({ expanded: false, buttonText: buttonText.more, buttonIcon: PlusIcon })
      const sectionTop = document.getElementById('section-campaign-wins')
      sectionTop?.scrollIntoView({ behavior: 'smooth' })
    } else {
      setShowMoreButton({ expanded: true, buttonText: buttonText.less, buttonIcon: MinusIcon })
      const element = document.getElementById('show-more-wrapper')
      element?.scrollIntoView({ behavior: 'smooth' })
    }
  }

  return (
    <GridItem
      id="section-campaign-wins"
      span={{ medium: 12, large: 12 }}
      columnStart={{ medium: 1, large: 1 }}
    >
      <Typography tag="h2" textStyle="sb-text-heading-large" className={styles.campaignWinHeader}>
        {headerComponent?.data.header}
      </Typography>
      <Typography textStyle="sb-text-body-default-regular" className={styles.campaignWinStandfirst}>
        {headerComponent?.data.standfirst}
      </Typography>
      <GridItem span={{ medium: 12, large: 12 }} columnStart={{ medium: 1, large: 1 }}>
        <div className={styles.campaignWinThreeCol} data-testid="campaigns-win">
          {winsComponentCards.map((win) => {
            const { img, header, standfirst, label } = win.data

            return (
              <StandardCard
                imageAlt={img.alt}
                imageSrc={img.src}
                title={header}
                standFirst={standfirst}
                label={label}
                key={header}
              />
            )
          })}
        </div>
        {displayShowMoreButton && (
          <div className={styles.showMoreWrapper} id="show-more-wrapper">
            <MoreButton
              aria-expanded={showMoreButton.expanded}
              buttonLabel={showMoreButton.buttonText}
              className={styles.showMoreWrapperButton}
              dataTestId="show-more-button"
              hideButton={!displayShowMoreButton}
              icon={showMoreButton.buttonIcon}
              onClick={() => handleShowMoreClick()}
              type="button"
            />
          </div>
        )}
      </GridItem>
    </GridItem>
  )
}

///////// IMPLEMENTATION /////////

type Props = {
  components: CampaignsHomepageContentItem[]
}
