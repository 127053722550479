import type { FunctionComponent } from 'react'
import React from 'react'
import { ButtonLink, TypographyV2 as Typography } from '@which/seatbelt'

import { JumplinksInline } from '../../../../shared/components/JumplinksInline'
import styles from './MyAppointmentHeader.module.scss'

export const MyAppointmentHeader: FunctionComponent<Props> = ({
  title,
  standfirst,
  description,
}) => {
  return (
    <>
      <Typography
        tag="h1"
        textStyle="sb-text-heading-page-title"
        className={styles.myAppointmentHeaderTitle}
      >
        {title}
      </Typography>
      <Typography
        textStyle="sb-text-heading-medium"
        className={styles.myAppointmentHeaderStandfirst}
      >
        {standfirst}
      </Typography>
      <Typography tag="p" textStyle="sb-text-body-default-regular">
        {description}
      </Typography>
      <div className={styles.myAppointmentHeaderLinkContainer}>
        <JumplinksInline
          className={styles.myAppointmentHeaderJumplinks}
          links={[
            { label: 'Upcoming appointments', id: 'upcoming-appointments' },
            { label: 'Past appointments', id: 'past-appointments' },
          ]}
        />
        <div>
          <Typography
            className={styles.myAppointmentsStandfirst}
            textStyle="sb-text-body-default-regular"
          >
            Have another issue? Book a new appointment
          </Typography>
          <ButtonLink href="/tool/book-appointment" className={styles.myAppointmentHeaderButton}>
            Book an appointment
          </ButtonLink>
        </div>
      </div>
    </>
  )
}

type Props = {
  title: string
  standfirst: string
  description: string
}
