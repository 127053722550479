import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { assertNever } from '@which/shared'

import {
  useVerticalAboutWhichPageQuery,
  useVerticalCampaignsPageQuery,
  useVerticalCrPageQuery,
  useVerticalMyAppointmentsPageQuery,
  useVerticalNewsFullListingPageQuery,
  useVerticalNewsPageQuery,
  useVerticalPolicyAndInsightPageQuery,
  useVerticalReviewsPageQuery,
  useVerticalSitemapPageQuery,
} from '../../../generated/frontend'
import type { PageParams } from '../../../routes'
import { usePageProps } from '../../../shared/usePageProps'
import type { VerticalTemplateName } from '../vertical-types'

export const useVerticalPageQuery = () => {
  const { template, context } = usePageProps()
  const { verticalSlug } = useParams<PageParams>()
  const verticalTemplate = template as VerticalTemplateName

  const useQuery = useMemo(() => {
    switch (verticalTemplate) {
      case 'CR Vertical':
        return useVerticalCrPageQuery
      case 'Reviews Vertical':
        return useVerticalReviewsPageQuery
      case 'News Vertical':
        return useVerticalNewsPageQuery
      case 'News Full Listing':
        return useVerticalNewsFullListingPageQuery
      case 'About Which Vertical':
        return useVerticalAboutWhichPageQuery
      case 'Policy and Insight Vertical':
        return useVerticalPolicyAndInsightPageQuery
      case 'Campaigns Vertical':
        return useVerticalCampaignsPageQuery
      case 'Sitemap Vertical':
        return useVerticalSitemapPageQuery
      case 'My Appointments Vertical':
        return useVerticalMyAppointmentsPageQuery
      default:
        assertNever(verticalTemplate)
    }
  }, [verticalTemplate])

  return useQuery({
    variables: { verticalSlug, context: context || '', pageNumber: 1 },
  })
}
