import React from 'react'
import { GridItem } from '@which/seatbelt'

import { Breadcrumb } from '../../../../shared/components/Breadcrumb'
import { VerticalAboutWhichMore } from '../../components/AboutWhich/More'
import { VerticalAboutWhichNavigation } from '../../components/AboutWhich/VerticalNavigation'
import { VerticalHeader } from '../../components/VerticalHeader'
import styles from '../../VerticalPage.module.scss'
import navStyles from './AboutWhich.module.scss'

export const getAboutWhichVerticalComponents = ({ header, aboutWhich }) => {
  return {
    VerticalHeader: () => (
      <GridItem span={{ medium: 8, large: 8 }} columnStart={{ medium: 3, large: 3 }}>
        <VerticalHeader
          description={header.description}
          title={header.title}
          standfirst={header.standfirst}
          size={5}
          className={styles.verticalHeader}
        />
      </GridItem>
    ),
    AboutWhichArticlesNavigation: () => (
      <GridItem span={{ medium: 8, large: 8 }} columnStart={{ medium: 3, large: 3 }}>
        <VerticalAboutWhichNavigation aboutWhich={aboutWhich} type="articles" />
      </GridItem>
    ),
    AboutWhichWidgetsNavigation: () => (
      <section className={aboutWhich?.promoUrls?.length ? navStyles.awPromoBlockWrapper : ''}>
        <div className="w-page-wrapper">
          <GridItem span={{ medium: 12, large: 12 }}>
            <VerticalAboutWhichNavigation aboutWhich={aboutWhich} type="widgets" />
          </GridItem>
        </div>
      </section>
    ),
    AboutWhichMore: () => <VerticalAboutWhichMore aboutWhich={aboutWhich} />,
    Breadcrumb: () => <Breadcrumb heading={{ text: header.title }} currentPage={null} links={[]} />,
  }
}
