import type { FunctionComponent } from 'react'
import React, { useState } from 'react'
import { TypographyV2 as Typography } from '@which/seatbelt'

import { MoreButton } from '../../../../shared/components/MoreButton'
import type { MyAppointmentProps } from '../MyAppointment/MyAppointment'
import { MyAppointment } from '../MyAppointment/MyAppointment'
import styles from './MyAppointments.module.scss'

export const MyAppointments: FunctionComponent<Props> = ({
  appointments,
  title,
  standFirst,
  id,
  noAppointmentData,
}) => {
  const [casesToShow, setCasesToShow] = useState(4)
  return (
    <div className={styles.myAppointments} id={id}>
      <Typography className={styles.myAppointmentsTitle} textStyle="sb-text-heading-large" tag="h2">
        {title}
      </Typography>
      {standFirst && (
        <Typography
          className={styles.myAppointmentsStandfirst}
          textStyle="sb-text-body-default-regular"
        >
          {standFirst}
        </Typography>
      )}
      {appointments.length > 0 ? (
        <>
          {appointments.slice(0, casesToShow).map((appointment) => (
            <div className={styles.myAppointmentsAppointment} key={appointment.refNumber}>
              <MyAppointment {...appointment} />
            </div>
          ))}
          <MoreButton
            dataTestId="show-more-button"
            hideButton={casesToShow >= appointments.length}
            onClick={() => setCasesToShow(casesToShow + 4)}
            buttonLabel="Show More"
            className={styles.myAppointmentsShowMore}
          />
        </>
      ) : (
        <Typography
          className={styles.myAppointmentsNoAppointments}
          textStyle="sb-text-body-default-regular"
        >
          {noAppointmentData}
        </Typography>
      )}
    </div>
  )
}

type Props = {
  appointments: MyAppointmentProps[]
  title: string
  standFirst?: string
  id?: string
  noAppointmentData: string
}
