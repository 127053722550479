import React from 'react'
import { GridItem } from '@which/seatbelt'

import type {
  Callout as CalloutType,
  ConsumerRegulations as ConsumerRegulationsType,
  LatestNews,
  VerticalCrPageQuery,
} from '../../../../generated/frontend'
import { Breadcrumb } from '../../../../shared/components/Breadcrumb/Breadcrumb'
import { Callout } from '../../../../shared/components/Callout/Callout'
import { ConsumerRegulations } from '../../../../shared/components/ConsumerRegulations/ConsumerRegulations'
import { LocalFooter } from '../../../../shared/components/LocalFooter/LocalFooter'
import { VerticalLatestNews } from '../../components/VerticalLatestNews'

export const getCrVerticalComponents = (data: VerticalCrPageQuery['verticalPage']) => {
  const {
    header,
    callout,
    consumerRegulations,
    latestNews,
    consumerRightsFooter,
    consumerRightsNavigation,
  } = data

  return {
    Callout: () => (
      <GridItem span={{ large: 10 }} columnStart={{ large: 2 }}>
        <Callout
          title={callout?.title}
          byline={callout?.byline}
          description={callout?.description}
          items={callout?.items as CalloutType['items']}
          imageFormat={callout?.imageFormat ?? 'square'}
        />
      </GridItem>
    ),
    ConsumerRegulations: () => (
      <GridItem span={{ small: 'full-bleed', medium: 'full-bleed', large: 'full-bleed' }}>
        <ConsumerRegulations data={consumerRegulations as ConsumerRegulationsType} />
      </GridItem>
    ),
    LatestConsumerRightsNews: () => (
      <GridItem span={{ small: 'full-bleed', medium: 'full-bleed', large: 'full-bleed' }}>
        <VerticalLatestNews news={latestNews as LatestNews} />
      </GridItem>
    ),
    ConsumerRightsFooter: () => (
      <GridItem>
        <LocalFooter data={consumerRightsFooter} />
      </GridItem>
    ),
    Breadcrumb: () => (
      <Breadcrumb
        heading={{ text: header.title }}
        currentPage={{ text: header.title, href: '' }}
        links={[]}
        localNavProps={consumerRightsNavigation}
      />
    ),
  }
}
