import type { DataLayerInfo, PageInfo } from '../../../../shared/types'

export const getAboutWhichVerticalDataLayer = (pageInfo: PageInfo) => {
  const aboutWhichDataLayer: DataLayerInfo = {
    ...pageInfo,
    pageType: 'article-listing',
    content_type: pageInfo.vertical === 'help' ? 'help' : 'about-which',
  }
  delete aboutWhichDataLayer.vertical
  delete aboutWhichDataLayer.sub_vertical

  return aboutWhichDataLayer
}
