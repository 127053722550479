import type { FunctionComponent } from 'react'
import React from 'react'
import { ArticleCard, CardRow } from '@which/seatbelt'

import type { NewsCard, NewsCardsCollectionItem } from '../../../../generated/frontend'
import { Link } from '../../../../shared/components/Link'
import styles from './CollectionLatestNews.module.scss'

export const CollectionLatestNews: FunctionComponent<Props> = ({ cards, taxonomy }) => {
  if (!cards?.length) {
    return null
  }

  const newsArticles = mapCards(cards)
  const link = {
    href: `/news/${taxonomy.slug}`,
    text: `See all ${taxonomy.name} news`,
  }

  return (
    <div data-testid="collection-latest-news">
      <CardRow className={styles.cardRow} link={link}>
        {newsArticles.map((article, index) => (
          <ArticleCard
            key={`article_${index}`}
            {...article}
            href={article.href}
            display="scroll-row"
            className={styles.articleCard}
          />
        ))}
        <div className={styles.viewAllLinkCard}>
          <Link href={link.href} includeIcon={true}>
            {link.text}
          </Link>
        </div>
      </CardRow>
    </div>
  )
}

///////// IMPLEMENTATION /////////

type Props = {
  cards: NewsCard[]
  taxonomy: NewsCardsCollectionItem['taxonomy']
}

const mapCards = (cards: NewsCard[]) =>
  cards.map((card) => ({
    title: card.heading,
    href: card.url,
    date: card.date?.rendered,
    dateTime: card.date?.iso,
    strapline: card.summary,
    image: card.image?.src,
    imageAlt: card.image?.alt,
    imageSources: card.image?.sources,
  }))
